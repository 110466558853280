import React from 'react'
import Col from 'react-bootstrap/lib/Col'
import Row from 'react-bootstrap/lib/Row'
import Plyr from 'react-plyr'

import PageController from './PageController'

import Hero from "../components/Hero"

// Styles
import '../assets/css/external.css'
//import '../assets/css/bootstrap.min.css'
import '../assets/css/style.css'
import '../assets/css/style-dark.css'

// Images
import ingredients from '../assets/images/ingredients3.png'
import cook from '../assets/images/cook3.png'
import eat from '../assets/images/eat3.png'


class Home extends PageController {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Hero />

        <div className="video-wrapper">
          <Plyr
            type="video" // or "vimeo"
            url={require("../assets/videos/explainer.mp4")}
            poster={require("../assets/images/explainer-poster.png")}
          />
        </div>

        <div className="main-content skills">
            <div className="container intro-boxes">
                <Row>
                    <div className="heading heading-1 mb-50 text--center">
                        <p className="heading--subtitle">Why Cheffeurs?</p>
                        <div className="divider--shape-4"></div>
                        <p className="heading--desc">
                            How often do you find yourself throwing out food you never got around to cooking? You're not alone. <a href="https://www.theguardian.com/environment/2018/apr/18/americans-waste-food-fruit-vegetables-study">Americans throw away 150,000 tons of food per day</a>.
                            Occasionally we're not in the mood to cook at home, so we order in a few too many times and before you know it our groceries go bad. It's a habit that contributes to massive food waste. Wouldn't it be great to order in, but still use your ingredients?
                        </p>

                        <h4 className="caps push-t-40">Introducing Cheffeurs</h4>
                    </div>
                </Row>

                <Row>
                    <Col md={4} sm={4} xs={12}>
                        <div className="info-image">
                            <span className="helper"></span>
                            <img src={ingredients} alt="Use your ingredients" />
                        </div>
                        <div className="info-description">
                            You tell us what ingredients you have at home, along with any dietary restrictions you may have.
                        </div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                        <div className="info-image">
                            <span className="helper"></span>
                            <img src={cook} alt="Get matched with a cook" />
                        </div>
                        <div className="info-description">
                            We match you up with a home cook who knows how to work with those ingredients. We background check and test all our home cooks.
                        </div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                        <div className="info-image">
                            <span className="helper"></span>
                            <img src={eat} alt="Enjoy your meal" />
                        </div>
                        <div className="info-description">
                            Your Cheffeur will whip up a quick, delicious meal using your fresh ingredients.
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
      </div>
    )
  }
}

export default Home
