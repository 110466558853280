import React from 'react'
import { Animated } from "react-animated-css"

import PageController from '../PageController'
//import API from '../../models/Api'
import { CurrentCook } from '../../models/Cook'

// Styles
import '../../assets/css/external.css'
import '../../assets/css/style.css'
import '../../assets/css/style-dark.css'


export default class Status extends PageController {
  constructor() {
    super()
    this.headImage = React.createRef()

    this.state = {
      networkError: false,
      isLoading: false,
      cook: {

      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this._onMount()

    console.log(CurrentCook.isAuthenticated())
  }

  authenticatedContent() {
    this.props.loading(true)

    CurrentCook.refresh({}, success => {
      this.props.loading(false)

    }, error => {

    })
  }

  renderPage() {
    if (CurrentCook.applicationPending()) {
      return (
        <section id="contact1" className="contact contact-1">
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={300}>
            <div className="heading heading-1 text--center mb-50">
              <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={600}>
                <p className="heading--subtitle">Your application is under review.</p>
              </Animated>
              <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
                <h2 className="heading--title animate jello caps">We'll be in contact soon</h2>
              </Animated>
            </div>
          </Animated>
        </section>
      )

    } else if (CurrentCook.applicationAccepted()) {
      return (
        <section id="contact1" className="contact contact-1">
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={300}>
            <div className="heading heading-1 text--center mb-50">
              <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={600}>
                <p className="heading--subtitle">Your application has been accepted.</p>
              </Animated>
              <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
                <h2 className="heading--title animate jello caps">Download the cook app</h2>
              </Animated>

              <Animated animationIn="flipInX" animationOut="fadeOut" animationInDelay={1900} isVisible={true}>
                <a href="/iosdownload" className="download-button">
                  <img alt="Available on the App Store" src={require("../../assets/images/buttons/apple-button.svg")} />
                </a>
                <a href="/androiddownload" className="download-button">
                  <img alt="Get it on Google Play" src={require("../../assets/images/buttons/google-button.svg")} />
                </a>
              </Animated>
            </div>
          </Animated>
        </section>
      )
    }
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        {this.renderPage()}
      </Animated>
    )
  }
}
