import React, { Component } from 'react'
//import { TransitionGroup, CSSTransition } from "react-transition-group"

import logo from "../assets/images/logo/logo-light.png"

import { BrowserRouter as Router, Route, NavLink, Switch, Redirect } from "react-router-dom"

import Footer from "../components/Footer"

import Home from '../pages/Home'
import Cities from '../pages/Cities'
import Apply from '../pages/Apply'
import Privacy from '../pages/Privacy'
import Terms from '../pages/Terms'
import Interview from '../pages/cooks/Interview'
import Status from '../pages/cooks/Status'

import { CurrentCook } from '../models/Cook'

export default class Header extends Component {

  constructor() {
    super()

    this.state = {
      scrollPosition: 0,
      navbarCollapsed: true,
      headerBackground: "inactive-header"
    }
  }

  componentDidMount() {
    const esto = this

    window.addEventListener("scroll", function (event) {
      esto.setState({scrollPosition: esto.scrollY})
    })
  }

  _navbarPressed() {
    console.log(!this.state.navbarCollapsed)
    this.setState({navbarCollapsed: !this.state.navbarCollapsed})
  }

  _closeNavbar() {
    this.setState({navbarCollapsed: true})
  }

  render() {
    const navbarState = this.state.navbarCollapsed ? "collapse" : ""

    return (
      <Router>
        <div>
          <div>
            <Route render={({ location }) => (
              <header id="navbar-spy" className="header header-1 header-dark header-fixed">
                  <nav id="primary-menu" className="navbar navbar-fixed-top">
                      <div className="container">
                          <div className="navbar-header">
                              <button type="button" className="navbar-toggle" data-target="#navbar-collapse-1" onClick={() => this._navbarPressed()}>
                                  <span className="sr-only">Toggle navigation</span>
                                  <span className="icon-bar"></span>
                                  <span className="icon-bar"></span>
                                  <span className="icon-bar"></span>
                              </button>
                              <NavLink to="/" className="logo">
                                  <img className="logo-light" src={logo} alt="Cheffeurs Logo" draggable="false" />
                                  <img className="logo-dark" src={logo} alt="Cheffeurs Logo" draggable="false" />
                              </NavLink>
                              <span className="tagline">Putting the cook in home cooking.™</span>
                          </div>

                          <div className={navbarState+" navbar-collapse pull-right"} id="navbar-collapse-1">
                              <ul className="nav navbar-nav nav-pos-right navbar-left">
                                  <li className="">
                                      <NavLink to="/" className="menu-item" onClick={() => this._closeNavbar()}>Home</NavLink>
                                  </li>

                                  <li className="">
                                      <NavLink to="/cities/" className="menu-item" onClick={() => this._closeNavbar()}>Cities</NavLink>
                                  </li>
                              </ul>
                              <div className="module module-reservation pull-left">
                                <NavLink to="/apply" className="btn-popup btn-popup-theme" onClick={() => this._closeNavbar()}>Become a Cheffeur</NavLink>
                              </div>
                          </div>
                      </div>
                  </nav>

              </header>
              )}
            />

            <Switch>
              <Route path="/" exact render={() => <Home notification={this.props.notification} loading={this.props.loading} />} />
              <Route path="/cities/" exact render={() => <Cities notification={this.props.notification} loading={this.props.loading} />} />
              <Route path="/privacy/" exact render={() => <Privacy notification={this.props.notification} loading={this.props.loading} />} />
              <Route path="/terms/" exact render={() => <Terms notification={this.props.notification} loading={this.props.loading} />} />
              <Route path="/apply" exact render={() => (
                CurrentCook.isAuthenticated() ? (
                  <Redirect to="/apply/interview" />
                ) : (
                  <Apply notification={this.props.notification} modal={this.props.modal} loading={this.props.loading} />
                )
              )} />
              <Route path="/apply/interview" exact render={() => (
                CurrentCook.isAuthenticated() ? (
                  <Interview notification={this.props.notification} loading={this.props.loading} />
                ) : (
                  <Redirect to="/apply" />
                )
              )} />
              <Route path="/apply/status" exact render={() => (
                CurrentCook.isAuthenticated() ? (
                  <Status notification={this.props.notification} loading={this.props.loading} />
                ) : (
                  <Redirect to="/apply" />
                )
              )} />
            </Switch>
            </div>
          <Footer />
        </div>
      </Router>
    )
  }
}
