import React, { Component } from 'react'
import { Animated } from "react-animated-css"

import API from '../models/Api'
import { CurrentCook } from '../models/Cook'

// Styles
import '../assets/css/external.css'
import '../assets/css/style.css'
import '../assets/css/style-dark.css'


class PageController extends Component {
  constructor() {
    super()

    this.state = {
      networkError: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  _onMount = () => {
    this.setState({isLoading: true})

    if (CurrentCook.isAuthenticated()) {
      // Do logged-in things
      //API.logout(()=>{},()=>{})
      this.authenticatedContent()

    } else {
      API.authenticateWithSaved((authData) => {
        if (authData) {
          console.log("SUCCESSFUL AUTH")
          // Do logged-in things
        }

        this.setState({networkError: false})
        // Removing auth data for testing
        //API.logout(()=>{},()=>{})

      }, (error) => {
        console.log(error)
        // Send them to the login page
        if (error.problem === "NETWORK_ERROR") {
          // Let them know the connection is not working (or the website not responding)
          this.setState({
            isLoading: false,
            networkError: true
          })

        } else {
          this.setState({ isLoading: false }, () => {
            // Redirect to login
          })
        }
      })
    }
  }

  _showContent() {
    console.log("PAGE CONTROLLER")
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        {this.props.children}
      </Animated>
    )
  }
}

export default PageController
