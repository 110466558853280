import API from './Api'
import { deserialize } from 'serializr'

export default class BaseClass {
  ignoreKeys(klass, ignored, ignoreUndefined = true) {
    // We don't want to edit this in place, it's better to return a new object
    //const result = new this.constructor.name
    const result = new klass()
    const esto = this

    Object.keys(this).forEach(key => {
      if (!ignored.includes(key) && !(ignoreUndefined && (esto[key] === undefined || esto[key].length === 0))) {
        result[key] = esto[key]
      }
    })

    return result
  }

  getAssociatedResource(resource, path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""

    API.endpoint
      .get(path+"/"+resourceId, params)
      .then((response) => {
        if (response.ok) {
          let newResource = response.data[Object.keys(response.data)[0]]

          if (typeof newResource == "string") {
            success(newResource)

          } else {
            success(deserialize(resource, newResource))
          }

        } else {
          console.log("THERE WAS AN ERROR GETTING RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }

  getResources(resource, path, params = {}, success, error) {
    API.endpoint
      .get(path, params)
      .then((response) => {
        if (response.ok) {
          let resources = response.data[Object.keys(response.data)[0]]
          success(deserialize(resource, resources))

        } else {
          console.log("THERE WAS AN ERROR GETTING RESOURCES")
          console.log(response)
          error(response)
        }
      })
  }

  putResource(resource, path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""
    console.log(params)
    API.endpoint
      .put(path+"/"+resourceId, params)
      .then((response) => {
        if (response.ok) {
          let newResource = response.data[Object.keys(response.data)[0]]

          if (typeof newResource == "string") {
            success(newResource)

          } else {
            success(deserialize(resource, newResource))
          }

        } else {
          console.log("THERE WAS AN ERROR PUTTING RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }

  postResource(resource, path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""

    API.endpoint
      .post(path+"/"+resourceId, params)
      .then((response) => {
        if (response.ok) {
          let newResource = response.data[Object.keys(response.data)[0]]

          if (typeof newResource == "string") {
            success(newResource)

          } else {
            success(deserialize(resource, newResource))
          }

        } else {
          console.log("THERE WAS AN ERROR POSTING RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }

  deleteResource(path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""

    API.endpoint
      .delete(path+"/"+resourceId, params)
      .then((response) => {
        if (response.ok) {
          const message = response.data[Object.keys(response.data)[0]]
          success(message)

        } else {
          console.log("THERE WAS AN ERROR DELETING RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }

  static getResource(resource, path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""

    API.endpoint
      .get(path+"/"+resourceId, params)
      .then((response) => {
        if (response.ok) {
          let newResource = response.data[Object.keys(response.data)[0]]

          if (typeof newResource == "string") {
            success(newResource)

          } else {
            success(deserialize(resource, newResource))
          }

        } else {
          console.log("THERE WAS AN ERROR GETTING RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }

  static getResources(resource, path, params = {}, success, error) {
    API.endpoint
      .get(path, params)
      .then((response) => {
        if (response.ok) {
          let resources = response.data[Object.keys(response.data)[0]]

          success(deserialize(resource, resources))

        } else {
          console.log("THERE WAS AN ERROR GETTING RESOURCES")
          console.log(response)
          error(response)
        }
      })
  }

  /*
    Multi-part form data
  */
  putMultipartResource(resource, path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""

    API.endpoint
      .put(path+"/"+resourceId, params, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        if (response.ok) {
          let newResource = response.data[Object.keys(response.data)[0]]

          if (typeof newResource == "string") {
            success(newResource)

          } else {
            success(deserialize(resource, newResource))
          }

        } else {
          console.log("THERE WAS AN ERROR PUTTING MULTIPART RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }

  postMultipartResource(resource, path, params = {}, success, error) {
    let resourceId = params.id ? params.id : ""

    API.endpoint
      .post(path+"/"+resourceId, params, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        if (response.ok) {
          let newResource = response.data[Object.keys(response.data)[0]]

          if (typeof newResource == "string") {
            success(newResource)

          } else {
            success(deserialize(resource, newResource))
          }

        } else {
          console.log("THERE WAS AN ERROR POSTING MULTIPART RESOURCE")
          console.log(response)
          error(response)
        }
      })
  }
}
