import React, { Component } from 'react'
import ReactNotification from "react-notifications-component"
import Modal from 'react-modal'

import Header from './components/Header'

Modal.setAppElement('#root')

const defaultModalStyle = {
  zIndex: 10000,
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor       : "#1d1d1d",
    border                : "10px solid #222"
  }
}

class App extends Component {
  constructor() {
    super()
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()

    this.handleModal = this.handleModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.loading = this.loading.bind(this)

    this.state = {
      isLoading: false,
      modalOpen: false,
      modalTitle: "Sample Modal",
      modalContent: <div />,
      modalStyle: defaultModalStyle
    }
  }

  addNotification(title, message, type) {
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "flipInX"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 5000 },
      dismissable: { click: true }
    })
  }

  loading(status) {
    console.log(this)
    this.setState({isLoading: status})
  }

  handleModal(action, component) {
    if (action === "open") {
      this.setState({
        modalOpen: true,
        modalContent: component
      })

    } else {
      this.setState({
        modalOpen: false
      })
    }
  }

  afterOpenModal() {
    
  }

  render() {
    return (
      <div>
        {this.state.isLoading &&
          <div className="preloader">
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
          </div>
        }

        <ReactNotification ref={this.notificationDOMRef} />

        <Modal
          ref="modal"
          isOpen={this.state.modalOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={() => this.handleModal("close")}
          style={this.state.modalStyle}
          overlayClassName="modal-overlay"
        >
          {this.state.modalContent}
        </Modal>

        <Header notification={this.addNotification} modal={this.handleModal} loading={this.loading} />
      </div>
    )
  }
}



export default App
