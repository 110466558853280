import { create } from 'apisauce'
//import _ from 'lodash'
import moment from 'moment'
import tz from 'moment-timezone' // eslint-disable-line no-unused-vars
import Cookies from 'js-cookie'

import Cook, { CurrentCook } from './Cook'

import { deserialize } from 'serializr'

//export const BASE_URL = 'http://0.0.0.0:3001' // Local
//export const BASE_URL = 'http://10.0.2.2:3001' // Android emulator local
//export const BASE_URL = 'http://192.168.0.142' // Android device local
//export const BASE_URL = 'http://10.0.1.9:3001' // Home
//export const BASE_URL = 'http://10.0.1.76:3001' // Laptop
//export const BASE_URL = 'http://10.2.1.4:3001' // Office
//export const BASE_URL = 'http://173.230.136.194:3001' // Linode
//export const BASE_URL = 'http://198.199.95.64:3001' // FMBI dev
export const BASE_URL = 'https://api.cheffeurs.com'


/*
  Auth strategy:

  Log in and store the auth_token and email in a cookie. Send them in the header
  with each request. If at any point a 401 unauthorized response is returned,
  clear the auth_token from the cookie and consider the user logged out and
  require re-authentication.
*/

class Api {
  constructor() {
    this.endpoint = create({
      baseURL: BASE_URL,
      timeout: 10000,
      headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
    })
  }

  baseURL() {
    return BASE_URL
  }

  register(cook, success, error) {
    const endpoint = this.endpoint

    cook.time_zone = moment.tz.guess()

    this.fetchAuth((authData) => {
      endpoint.post('/cooks/sign_up', {cook: cook}).then((response) => {
        console.log(response)
        if (response.ok) {
          this.setAuthentication({
            auth_token: response.data.auth_token,
            cook: response.data.cook
          })

          console.log(response)
          success(response)

        } else {
          console.log("THERE WAS AN ERROR (register)")
          console.log(response)
          error(response)
        }
      })

    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH NEW")
      console.log(error)
    })
  }

  /* Manual testing:

     curl POST -H 'Accept: application/json' -H 'Content-type: application/json' --url "http://10.0.1.9:3000/cooks/sign_in" -d '{"cook": {"email": "custymer@mailinator.com", "password": "password"}}'
     curl ... -H 'X-Cook-Email: custymer@mailinator.com', -H 'X-Cook-Token: [token]' ...
     */
  authenticate(email, password, success, error) {
    const endpoint = this.endpoint

    this.fetchAuth((authData) => {
      endpoint
        .post('/cooks/sign_in', {cook: {email: email, password: password}})
        .then((response) => {
          console.log(response)
          if (response.ok) {
            console.log("AUTH DATA: ", authData)
            this.setAuthentication({
              auth_token: response.data.auth_token,
              cook: response.data.cook
            })

            console.log(response)
            success(response)

          } else {
            console.log("THERE WAS AN ERROR (authenticate)")
            console.log(response)
            error(response)
          }
        })
    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH NEW")
      console.log(error)
    })
  }

  authenticateWithSaved(success, error) {
    const endpoint = this.endpoint

    this.fetchAuth((authData) => {
      // Don't bother and return false if there are no saved credentials
      //console.log(authData)
      if (authData.cook_id && authData.cook_id.length === 0) {
        success(false)
        error({message: "No login data exists."})
        return
      }

      endpoint.setHeaders({ 'X-Cook-Email': authData.email, 'X-Cook-Token': authData.auth_token })
      console.log(authData)
      if (authData.cook_id != null) {
        endpoint
          .get('/cooks/'+authData.cook_id, {})
          .then((response) => {
            if (response.ok) {
              this.setAuthentication({
                auth_token: authData.auth_token,
                cook: response.data.cook
              })

              success(response)

            } else {
              console.log("THERE WAS AN ERROR AUTHENTICATING WITH SAVED")
              error(response)
            }
          })

      } else {
        error({message: "No login info provided."})
      }

    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH SAVED")
      console.log(error)
    })
  }

  /*
    Social logins
  */
  authenticateWithFacebook(user, credentials, success, error) {
    const endpoint = this.endpoint

    this.fetchAuth((authData) => {
      endpoint.post('/cooks/sign_up', {
        cook: {
          email: user.email,
          // Use just the last 16 characters for the password
          password: credentials.access_token.substr(-16),
          password_confirmation: credentials.access_token.substr(-16),
          provider: "facebook",
          provider_token: credentials.access_token,
          uid: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          time_zone: moment.tz.guess()
        }

      }).then((response) => {
        console.log(response)
        if (response.ok) {
          this.setAuthentication({
            auth_token: response.data.auth_token,
            cook: response.data.cook
          })

          console.log(response)
          success(response)

        } else {
          console.log("THERE WAS AN ERROR (register)")
          console.log(response)
          error(response)
        }
      })

    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH NEW")
      console.log(error)
    })
  }

  authenticateWithTwitter(user, credentials, success, error) {
    const endpoint = this.endpoint

    this.fetchAuth((authData) => {
      endpoint.post('/cooks/sign_up', {
        cook: {
          email: user.email,
          // Use just the last 16 characters for the password
          password: credentials.oauth_token.substr(-16),
          password_confirmation: credentials.oauth_token.substr(-16),
          provider: "twitter",
          provider_token: credentials.oauth_token + " --provider_secret-- " + credentials.oauth_token_secret,
          uid: user.id,
          first_name: user.name,
          last_name: "",
          avatar_url: user.profile_image_url_https,
          time_zone: moment.tz.guess()
        }

      }).then((response) => {
        console.log(response)
        if (response.ok) {
          this.setAuthentication({
            auth_token: response.data.auth_token,
            cook: response.data.cook
          })

          console.log(response)
          success(response)

        } else {
          console.log("THERE WAS AN ERROR (register)")
          console.log(response)
          error(response)
        }
      })

    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH NEW")
      console.log(error)
    })
  }

  authenticateWithGoogle(user, credentials, success, error) {
    const endpoint = this.endpoint

    this.fetchAuth((authData) => {
      endpoint.post('/cooks/sign_up', {
        cook: {
          email: user.email,
          // Use just the last 16 characters for the password
          password: credentials.access_token.substr(-16),
          password_confirmation: credentials.access_token.substr(-16),
          provider: "google",
          provider_token: credentials.access_token,
          uid: user.id,
          first_name: user.given_name,
          last_name: user.family_name,
          avatar_url: user.picture,
          time_zone: moment.tz.guess()
        }
      }).then((response) => {
        console.log(response)
        if (response.ok) {
          this.setAuthentication({
            auth_token: response.data.auth_token,
            cook: response.data.cook
          })

          console.log(response)
          success(response)

        } else {
          console.log("THERE WAS AN ERROR (register)")
          console.log(response)
          error(response)
        }
      })

    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH NEW")
      console.log(error)
    })
  }

  setAuthentication(data) {
    console.log(data)
    this.endpoint.setHeaders({ 'X-Cook-Email': data.cook.email, 'X-Cook-Token': data.auth_token })

    Cookies.set('email', data.cook.email)
    Cookies.set('auth_token', data.auth_token)
    Cookies.set('cook_id', data.cook.id.toString())

    CurrentCook.assignCook(deserialize(Cook, data.cook))
    CurrentCook.authenticate()
    CurrentCook.auth_token = data.auth_token

    console.log(this.endpoint.headers)
  }

  setAuthenticationFromCookie() {
    if (typeof this.endpoint.headers['X-Cook-Token'] === "undefined" || (typeof this.endpoint.headers['X-Cook-Token'] !== "undefined" && this.endpoint.headers['X-Cook-Token'].length === 0)) {
      this.endpoint.setHeaders({
        'X-Cook-Email': Cookies.get('email'),
        'X-Cook-Token': Cookies.get('auth_token')
      })
    }
  }

  fetchAuth = (success, error) => {
    try {
      success({
        email: Cookies.get('email'),
        auth_token: Cookies.get('auth_token'),
        cook_id: Cookies.get('cook_id')
      })

    } catch (err) {
      console.log("ERROR RETRIEVING AUTH DATA")
      console.log(error)
      error(error)
    }
  }

  logout = async (success, error) => {
    const endpoint = this.endpoint

    endpoint
      .delete('/cooks/sign_out')
      .then((response) => {
        if (response.ok) {
          Cookies.remove('email')
          Cookies.remove('auth_token')
          Cookies.remove('cook_id')

          endpoint.setHeaders({ 'X-Cook-Email': '', 'X-Cook-Token': '' })
          console.log(response)
          success(response)

        } else {
          // If we remove the cookies, it serves the same purpose
          Cookies.remove('email')
          Cookies.remove('auth_token')
          Cookies.remove('cook_id')

          console.log("THERE WAS AN ERROR LOGGING OUT")
          console.log(response)
          error(response)
        }
      })
  }
}



const API = new Api()
export default API
