import React, { Component } from 'react';
import jsonp from "jsonp";
//import { NavLink } from "react-router-dom"
import { NavLink } from "react-router-dom"

import logo from "../assets/images/logo/logo-light.png"

export default class Footer extends Component {
  constructor() {
    super();

    this.newsletterEmail = React.createRef();
    this.submitNewsletterForm = this.submitNewsletterForm.bind(this);

    this.state = {
      status: "",
      message: ""
    }
  }

  submitNewsletterForm = (event) => {
    const email = this.newsletterEmail.current.value

    if (email) {
      jsonp(
        "https://cheffeurs.us19.list-manage.com/subscribe/post-json?u=d71dd045c39423fba880cd37e&id=d2f3c501cf&EMAIL=+"+email,
        {
          param: "c"
        },
        (err, data) => {
          if (err) {
            this.setState({
              status: "error",
              message: err
            });
          } else if (data.result !== "success") {
            this.setState({
              status: "error",
              message: data.msg
            });
          } else {
            this.setState({
              status: "success",
              message: data.msg
            });
          }
        }
      )
    }

    event.preventDefault()
  }

  render() {
    return (
      <footer id="footer" className="footer footer-1 text-center">
          <div className="footer--widget text--center">
              <div className="container">
                  <div className="row">
                      <div className="col-xs-12 col-sm-4 col-md-4">
                          <div className="footer--widget-content">
                              <h3>Follow Us</h3>
                              <div className="footer--social-icons">
                                  <a href="https://fb.me/Cheffeurs"><i className="fa fa-facebook"></i></a>
                                  <a href="https://twitter.com/cheffeurs"><i className="fa fa-twitter"></i></a>
                                  <a href="https://www.instagram.com/cheffeurs"><i className="fa fa-instagram"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                          <div className="footer--widget-content">
                              <img className="mb-20 footer-logo" src={logo} alt="logo" />
                              <p>Putting the cook in home cooking.™</p>
                          </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                          <div className="footer--widget-content">
                              <h3>Newsletter</h3>
                              <div className="widget--newsletter">
                                  <form className="footer--social-newsletter mailchimp-newsletter" onSubmit={this.submitNewsletterForm}>
                                      <input ref={this.newsletterEmail} type="email" name="email" className="form-control" placeholder="Subscribe Our Newsletter" />
                                      <button type="button" onClick={this.submitNewsletterForm}>Join</button>
                                  </form>
                                  {this.state.status.length > 0 &&
                                    <div className="subscribe-alert">
                                      <h5 className={"alert alert-"+this.state.status}>{this.state.message}</h5>
                                    </div>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="footer--copyright text-center">
              <div className="divider--shape-dark2"></div>
              <div className="container">
                  <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                          <div className="contact-info">
                              <span>305-882-9803</span> -
                              <span><a href='m&#97;&#105;&#108;to&#58;h%&#54;5&#108;lo&#64;&#99;&#104;e&#102;%6&#54;eu&#37;&#55;&#50;s&#46;c&#111;m'>&#104;el&#108;o&#64;&#99;hef&#102;e&#117;&#114;s&#46;&#99;&#111;m</a></span>
                              {/*<span><NavLink to="/privacy">Privacy</NavLink></span> -
                              <span><NavLink to="/terms">Terms</NavLink></span>*/}
                          </div>
                          <div className="site-meta">
                            <span><NavLink to="/privacy">Privacy</NavLink></span> -
                            <span><NavLink to="/terms">Terms</NavLink></span>
                          </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                          <span>&copy; 2019 Cheffeurs LLC</span>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
    );
  }
}
