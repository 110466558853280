import React from 'react'
import { Animated } from "react-animated-css"
import NumberFormat from 'react-number-format'
import {
  Redirect
} from 'react-router-dom'
import PageController from '../PageController'
//import API from '../../models/Api'
import { CurrentCook } from '../../models/Cook'

// Styles
import '../../assets/css/external.css'
import '../../assets/css/style.css'
import '../../assets/css/style-dark.css'

//import headerImage from "../../assets/images/apply.jpg"


export default class Interview extends PageController {
  constructor() {
    super()
    this.headImage = React.createRef()

    this.state = {
      networkError: false,
      isLoading: false,
      step: 1,
      redirect: null,
      cook: {
        data_ssn: null,
        data_work_authorized: true,
        address: null,
        city: null,
        state: "CA",
        zip: null,
        data_question_training: false,
        data_question_creativity: null,
        data_question_safety: null,
        data_question_resourcefulness: null,
        data_question_grease_fire: null
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    /*
      When they're in the application page we want to check whether they're already
      logged in so we can then decide what state they're in and show the right
      page accordingly.
    */
    this._onMount()

    console.log(CurrentCook.isAuthenticated())
  }

  authenticatedContent() {
    this.props.loading(true)

    CurrentCook.refresh({}, success => {
      if (CurrentCook.applicationPending() || CurrentCook.applicationAccepted()) {
        this.setState({redirect: <Redirect to='/apply/status' />})
      }

      this.props.loading(false)

    }, error => {

    })
  }

  submitForm = (event) => {
    event.preventDefault()

    if (this.state.step === 1) {
      this.setState({step: 2})
      window.scrollTo(0, 0)

    } else {
      console.log("submitting application")
      // Send the address as a combo of all address fields (city/state/zip)
      // Keep a reference to the original address so we can revert back to
      // it in case the submission fails.
      const oldAddr = this.state.cook.address
      const normalizedAddr = this.state.cook.address + ", " +
        this.state.cook.city + ", " + this.state.cook.state + " " +
        this.state.cook.zip

      this.setState({cook: {...this.state.cook, address: normalizedAddr}}, () => {
        CurrentCook.update(this.state.cook, success => {
          console.log(success)
          this.setState({redirect: <Redirect to='/apply/status' />})

        }, error => {
          console.log(error)
        })
      })
    }
  }

  renderPage() {
    if (this.state.step === 1) {
      return (
        <section id="contact1" className="contact contact-1">
          {this.state.redirect}
          <div className="heading heading-1 text--center mb-50">
            <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
              <p className="heading--subtitle">We need a little bit more information about you.</p>
            </Animated>
            {/*<Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
              <h2 className="heading--title animate jello">Become a Cheffeur</h2>
            </Animated>*/}
          </div>

          <div className="container">
            <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12">
              <div className="row">
                <div className="col-md-12">
                    <input type="text" className="form-control" name="cook[address]" id="address" placeholder="Address" onChange={(event) => this.setState({cook: {...this.state.cook, address: event.target.value}})} required />
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control" name="cook[city]" id="city" placeholder="City" onChange={(event) => this.setState({cook: {...this.state.cook, city: event.target.value}})} required />
                </div>
                <div className="col-md-6">
                  <select defaultValue="CA" className="form-control" name="time" id="timePopup" required="" aria-required="true" onChange={(event) => this.setState({cook: {...this.state.cook, state: event.target.value}})}>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control" name="cook[zip]" id="zip" placeholder="Zip Code" onChange={(event) => this.setState({cook: {...this.state.cook, zip: event.target.value}})} required />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <hr />
                  <p>To protect customers and offer reliable services, we perform background checks on all of our independent contractors. By applying, you agree to authorize Cheffeurs, Inc. to perform a criminal background check.</p>
                  <div className="contact-form text--center">
                    <form method="post" className="contactForm mb-0" onSubmit={this.submitForm}>
                        <div className="form-row">
                          <label>Provide your Social Security Number</label>
                          <NumberFormat displayType="input" placeholder="***-**-****" format="###-##-####" className="form-control" name="cook[data_ssn]" id="data_ssn" style={{textAlign: 'center'}} onValueChange={(values) => this.setState({cook: {...this.state.cook, data_ssn: values.value}})} required />
                        </div>

                        <div className="form-row">
                          <div>
                            <label>Are you authorized to work in the United States?</label>
                          </div>
                          <label className="radio-inline">
                            <input type="radio" value="true" checked name="cook[work_authorized]" id="work_authorized_yes" onChange={(event) => this.setState({cook: {...this.state.cook, work_authorized: true}})} />
                            Yes
                          </label>

                          <label className="radio-inline">
                            <input type="radio" value="false" name="cook[work_authorized]" id="work_authorized_no" onChange={(event) => this.setState({cook: {...this.state.cook, work_authorized: false}})} />
                            No
                          </label>
                        </div>

                        <div>
                          <button type="submit" className="btn btn--primary btn--block mt-10">Continue</button>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12">
                          <div className="contact-result"></div>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )

    } else {
      return (
        <section id="contact1" className="contact contact-1">
          {this.state.redirect}
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={300}>
            <div className="heading heading-1 text--center mb-50">
              <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={600}>
                <p className="heading--subtitle">Lastly, answer these questions and submit your application.</p>
              </Animated>
              {/*<Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
                <h2 className="heading--title animate jello">Become a Cheffeur</h2>
              </Animated>*/}
            </div>

            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="row">
                  <div className="contact-form text--center">
                    <form method="post" className="contactForm mb-0" onSubmit={this.submitForm}>
                      <div className="row">
                          <div className="form-row mb-40">
                            <div>
                              <label>Do you have any formal culinary training, or have you ever worked as a cook?</label>
                            </div>
                            <label className="radio-inline">
                              <input type="radio" value="true" name="cook[data_question_training]" id="data_question_training_yes" onChange={(event) => this.setState({cook: {...this.state.cook, data_question_training: true}})} />
                              Yes
                            </label>
                            <label className="radio-inline">
                              <input checked type="radio" value="false" name="cook[data_question_training]" id="data_question_training_no" onChange={(event) => this.setState({cook: {...this.state.cook, data_question_training: false}})} />
                              No
                            </label>
                          </div>

                          <div className="form-row">
                            <label>What dishes best showcase your creativity?</label>
                            <input type="text" className="form-control" name="cook[data_question_creativity]" id="data_question_creativity" placeholder="E.g. pasta, vegan, stir-fry" style={{textAlign: 'center'}} autoComplete="off" onChange={(event) => this.setState({cook: {...this.state.cook, data_question_creativity: event.target.value}})} required />
                          </div>

                          <div className="form-row">
                            <label>What would you do if you were cooking and realized you didn’t have all the ingredients you needed to make a dish?</label>
                            <input type="text" className="form-control" name="cook[data_question_resourcefulness]" id="data_question_resourcefulness" placeholder="I would..." style={{textAlign: 'center'}} autoComplete="off" onChange={(event) => this.setState({cook: {...this.state.cook, data_question_resourcefulness: event.target.value}})} required />
                          </div>

                          <div className="form-row">
                            <label>How do you put out a grease fire?</label>
                            <input type="text" className="form-control" name="cook[data_question_grease_fire]" id="data_question_grease_fire" placeholder="The first thing..." style={{textAlign: 'center'}} autoComplete="off" onChange={(event) => this.setState({cook: {...this.state.cook, data_question_grease_fire: event.target.value}})} required />
                          </div>

                          <div className="form-row">
                            <label>How do you ensure the safety of the meals you cook?</label>
                            <input type="text" className="form-control" name="cook[data_question_safety]" id="data_question_safety" placeholder="I make sure that..." style={{textAlign: 'center'}} autoComplete="off" onChange={(event) => this.setState({cook: {...this.state.cook, data_question_safety: event.target.value}})} required />
                          </div>

                          <div>
                              <button type="submit" className="btn btn--primary btn--block mt-10">Submit Application</button>
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="contact-result"></div>
                          </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Animated>
        </section>
      )
    }
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        {this.renderPage()}
      </Animated>
    )
  }
}
