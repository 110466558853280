import React, { Component } from 'react'

import { Animated } from "react-animated-css"
import jsonp from "jsonp"

import headerImage from "../assets/images/header.jpg"


export default class Hero extends Component {
  constructor() {
    super()

    this.betaEmail = React.createRef()
    this.headImage = React.createRef()
    this.submitBetaForm = this.submitBetaForm.bind(this)

    this.state = {
      status: "",
      message: ""
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const img = this.headImage.current

    img.onload = () => {
      img.classList.remove('hidden-until-load')
    	img.classList.add('animated', 'fadeIn')
    }
  }

  submitBetaForm = (event) => {
    const email = this.betaEmail.current.value

    if (email) {
      jsonp(
        "https://cheffeurs.us19.list-manage.com/subscribe/post-json?u=d71dd045c39423fba880cd37e&id=369afef3c8&EMAIL=+"+email,
        {
          param: "c"
        },
        (err, data) => {
          if (err) {
            this.setState({
              status: "error",
              message: err
            })
          } else if (data.result !== "success") {
            this.setState({
              status: "error",
              message: data.msg
            })
          } else {
            this.setState({
              status: "success",
              message: data.msg
            })
          }
        }
      )
    }

    event.preventDefault()
  }

  render() {
    return (
      <section id="slider" className="slider slide-overlay-dark">
        <div className="rev_slider_wrapper">
          <img src={headerImage} alt="Delicious home-cooked food" className="header-image hidden-until-load" ref={this.headImage} />

          <div className="slide-overlay">
            <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={1000}>
              <div className="slide--subheadline text--center">Too tired to cook?</div>
            </Animated>
            <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
              <div className="slide--headline text--center animate jello">Get a Home Cook On Demand</div>
            </Animated>
            <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1600}>
              <div className="slide--bio text--center animate jello">For just $29, we connect you with a home cook who will make you a delicious meal in your kitchen, using your ingredients.</div>
            </Animated>
            <Animated animationIn="flipInX" animationOut="fadeOut" animationInDelay={1900} isVisible={true}>
              <div className="slide-action">
               {/*<a href="https://itunes.apple.com/us/app/cheffeurs/id1450536254" className="download-button">
                  <img alt="Available on the App Store" src="assets/images/buttons/apple-button.svg" />
                </a>
                <a href="https://itunes.apple.com/us/app/cheffeurs-cook/id1450536397" className="download-button">
                  <img alt="Get it on Google Play" src="assets/images/buttons/google-button.svg" />
                </a>*/}
                <div className="social-newsletter">
                  <h4>Apply to Our Private Beta</h4>
                  <div className="widget--newsletter">
                    <form className="footer--social-newsletter mailchimp-beta" id="beta-signup-form" onSubmit={this.submitBetaForm}>
                      <input ref={this.betaEmail} type="email" name="email" className="form-control" placeholder="Enter your email address" />
                      <button type="button" onClick={this.submitBetaForm}>Apply</button>
                    </form>
                    {this.state.status.length > 0 &&
                      <div className="subscribe-alert">
                        <h5 className={"alert alert-"+this.state.status}>{this.state.message}</h5>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Animated>
          </div>
        </div>
      </section>
    )
  }
}
