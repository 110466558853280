import React from 'react'
//import Col from 'react-bootstrap/lib/Col'
//import Row from 'react-bootstrap/lib/Row'
import { Animated } from "react-animated-css"

import PageController from './PageController'

// Styles
import '../assets/css/external.css'
import '../assets/css/style.css'
import '../assets/css/style-dark.css'

// Images
//import headImage from '../assets/images/page-title/6.jpg'

class Cities extends PageController {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        <section id="page-title" className="page-title bg-overlay">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="title title-1 text-center">
                  <div className="title--content">
                    <div className="title--subtitle push">Serving the Following Cities</div>
                    <div className="title--heading">
                      <h2>Miami</h2>
                      <h2>San Francisco</h2>
                      <p><big>More cities coming soon.</big></p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Animated>
    )
  }
}

export default Cities
