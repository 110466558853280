import React from 'react'
import { Animated } from "react-animated-css"
//import { Modal, Button } from 'react-bootstrap'
import {
  Redirect,
  NavLink
} from 'react-router-dom'

import PageController from './PageController'
import API from '../models/Api'
import { CurrentCook } from '../models/Cook'

// Styles
import '../assets/css/external.css'
import '../assets/css/style.css'
import '../assets/css/style-dark.css'

import headerImage from "../assets/images/apply.jpg"


export default class Apply extends PageController {
  constructor() {
    super()
    this.headImage = React.createRef()

    this.state = {
      networkError: false,
      isLoading: false,
      isAuthenticated: false,
      cook: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: ""
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const img = this.headImage.current

    img.onload = () => {
      img.classList.remove('hidden-until-load')
    	img.classList.add('animated', 'fadeIn')
    }

    /*
      When they're in the application page we want to check whether they're already
      logged in so we can then decide what state they're in and show the right
      page accordingly.
    */
    this._onMount()

    console.log(CurrentCook.isAuthenticated())
  }

  authenticatedContent() {
    this.setState({isAuthenticated: true})

    CurrentCook.refresh({}, success => {
      return <Redirect to='/apply/interview' />

    }, error => {

    })
  }

  openModal(event) {
    let component = (
      <form method="post" className="contactForm mb-0" onSubmit={this.submitSignInForm}>
          <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <input type="email" className="form-control" name="cook[email]" id="email" placeholder="Email" onChange={(event) => this.setState({cook: {...this.state.cook, email: event.target.value}})} required />
              </div>

              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <input type="password" className="form-control" name="cook[password]" id="password" placeholder="Password" onChange={(event) => this.setState({cook: {...this.state.cook, password: event.target.value}})} required />
              </div>

              <div className="col-md-12">
                  <button type="submit" className="btn btn--primary btn--block mt-10">Sign In</button>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="contact-result"></div>
              </div>
          </div>
      </form>
    )

    this.props.modal("open", component)
  }

  submitRegistrationForm = (event) => {
    event.preventDefault()

    API.register(this.state.cook, (success) => {
      this.setState({isLoading: false})
      console.log(success)
      this.loginSuccess()

    }, (error) => {
      console.log(error)
      let errorMessage = error.data ? error.data.message : "We could not connect to the Cheffeurs server. Try again with a better connection."
      this.setState({isLoading: false, loginError: errorMessage})

      this.props.notification("Registration Error", errorMessage, "danger")
    })
  }

  submitSignInForm = (event) => {
    event.preventDefault()

    API.authenticate(this.state.cook.email, this.state.cook.password, (success) => {
      this.setState({isLoading: false})
      this.props.modal("close")
      console.log(success)
      this.loginSuccess()

    }, (error) => {
      console.log(error)
      let errorMessage = error.data ? error.data.error : "We could not connect to the Cheffeurs server. Try again with a better connection."
      this.setState({isLoading: false, loginError: errorMessage})

      this.props.notification("Authentication Error", errorMessage, "danger")
    })
  }

  loginSuccess() {
    console.log("PERFORM LOGIN STUFF")
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        <section id="slider" className="slider slim slide-overlay-dark">
          <div className="rev_slider_wrapper">
            <img src={headerImage} alt="Delicious home-cooked food" className="header-image hidden-until-load" ref={this.headImage} style={{position: 'relative', top: "-1450px"}} />
            <div className="slide-overlay slim">
              <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={1000}>
                <div className="slide--subheadline text--center">Make dough while making dough.</div>
              </Animated>
              <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
                <div className="slide--headline text--center animate jello">Become a Cheffeur</div>
              </Animated>
            </div>
          </div>
        </section>

        <section id="contact1" className="contact contact-1">
          <div className="container">
            <div className="col-md-12 col-lg-12 col-sm-12">
              <div className="row">
                <p>As a cook on Cheffeurs, you'll earn money by preparing meals in people's homes using ingredients provided by them. You don't need professional training, just the ability to make a delicious meal. After applying, you'll be be contacted by a member of our team.</p>
                <p><strong>Note:</strong> Check the <NavLink to="/cities/" className="menu-item" onClick={() => this._closeNavbar()}>cities</NavLink> page to see if applications are open for your city before applying.</p>
                <div className="contact-form">
                  <form method="post" className="contactForm mb-0" onSubmit={this.submitRegistrationForm}>
                      <div className="row">
                          <div className="col-md-6">
                              <input type="text" className="form-control" name="cook[first_name]" id="firstName" placeholder="First Name" onChange={(event) => this.setState({cook: {...this.state.cook, first_name: event.target.value}})} required />
                          </div>
                          <div className="col-md-6">
                              <input type="text" className="form-control" name="cook[last_name]" id="lastName" placeholder="Last Name" onChange={(event) => this.setState({cook: {...this.state.cook, last_name: event.target.value}})} required />
                          </div>
                          <div className="col-md-6">
                              <input type="email" className="form-control" name="cook[email]" id="email" placeholder="Email" onChange={(event) => this.setState({cook: {...this.state.cook, email: event.target.value}})} required />
                          </div>
                          <div className="col-md-6">
                              <input type="text" className="form-control" name="cook[phone]" id="phone" placeholder="Phone" onChange={(event) => this.setState({cook: {...this.state.cook, phone: event.target.value}})} required />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                              <input type="password" className="form-control" name="cook[password]" id="password" placeholder="Password" onChange={(event) => this.setState({cook: {...this.state.cook, password: event.target.value}})} required />
                          </div>
                          <div className="col-md-6">
                              <input type="password" className="form-control" name="cook[password_confirmation]" id="password_confirmation" placeholder="Password Confirmation" onChange={(event) => this.setState({cook: {...this.state.cook, password_confirmation: event.target.value}})} required />
                          </div>
                          <div className="col-md-12">
                              <button type="submit" className="btn btn--primary btn--block mt-10">Continue</button>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12">
                              <div className="contact-result"></div>
                          </div>
                      </div>
                  </form>

                  <div className="block-content text--center">
                    <p>
                      <button className="btn btn--link btn--primary" onClick={(event) => this.openModal(event)}>Already applied? Log in to continue or check your status</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Animated>
    )
  }
}
