import React from 'react'
//import Col from 'react-bootstrap/lib/Col'
//import Row from 'react-bootstrap/lib/Row'
import { Animated } from "react-animated-css"
import { NavLink } from "react-router-dom"

import PageController from './PageController'

// Styles
import '../assets/css/external.css'
import '../assets/css/style.css'
import '../assets/css/style-dark.css'

// Images
//import headImage from '../assets/images/page-title/6.jpg'

export default class Privacy extends PageController {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        <section id="page-title" className="page-title bg-overlay">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="title-sm title-1">
                  <div className="title--content">
                    <div className="title--subtitle push text-center">Privacy Policy</div>
                    <div className="title--heading">
                      <p>At Cheffeurs, we want to connect people through food and cooking by making home cooked meals accessible and affordable while reducing food waste. In this privacy policy, we tell you what information we receive from Cheffeurs diners and cooks, and how we use it to connect diners with cooks and continue to improve our services. Below, we explain how you can share with other diners and cooks in the Cheffeurs community as part of our mission to bring people together.</p>
                      <h4 className="caps">Scope of this Privacy Policy</h4>
                      <p>Cheffeurs (“Cheffeurs,” “we,” “our,” and/or “us”) values the privacy of individuals who use our application, websites, and related services (collectively, the “Cheffeurs Platform ”). This privacy policy (the “Privacy Policy”) explains how we collect, use, and share information from Cheffeurs users (“Users”), comprised of both Cheffeurs diners (“Diners”) and Cheffeurs cooks (including Cook applicants) (“Cooks”). Beyond the Privacy Policy, your use of Cheffeurs is also subject to our Terms of Service (<NavLink to="/terms">www.cheffeurs.com/terms</NavLink>).</p>

                      <h4 className="caps">Information We Collect</h4>
                      <h5>A. Information You Provide to Us</h5>
                      <p><strong>Registration Information.</strong> When you sign up for a Cheffeurs account, you give us your name, email address, and phone number. If you decide to sign up for Cheffeurs using your Facebook account, we will also get basic information from your Facebook profile like your name, gender, profile photo, and Facebook friends.</p>
                      <p><strong>User Profile Information.</strong> When you join the Cheffeurs community, you can create a Cheffeurs Profile to share fun facts about yourself, and discover mutual friends and interests. Filling out a profile is optional, and you can share as little or as much as you want. Your name (and for Cooks, Profile photos) is always part of your Profile. Read more below about how you can control who sees your Profile. You can also add a Business Profile to your account, which requires a designated business email address and payment method.</p>
                      <p><strong>Payment Method.</strong> When you add a credit card or payment method to your Cheffeurs account, a third party that handles payments for us will receive your card information. To keep your financial data secure, we do not store full credit card information on our servers.</p>
                      <p><strong>Communications.</strong> If you contact us directly, we may receive additional information about you. For example, when you contact our Customer Support Team, we will receive your name, email address, phone number, the contents of a message or attachments that you may send to us, and other information you choose to provide.</p>
                      <p><strong>Cook Application Information.</strong> If you decide to join our Cheffeurs Cook community, in addition to the basic registration information we ask you for your date of birth, physical address, Social Security number, Cook’s driver license information, and in some jurisdictions we may collect additional business license or permitting information. We share this information with our partners who help us by running background checks on Cooks to help protect the Cheffeurs community.</p>
                      <p><strong>Payment Information.</strong> To make sure Cooks get paid, we keep information about Cooks’ bank routing numbers, tax information, and any other payment information provided by Cooks.</p>
                      <h5>B. Information We Collect When You Use the Cheffeurs Platform</h5>
                      <p><strong>Location Information.</strong> Cheffeurs is all about connecting Cooks and Diners. To do this, we need to know where you are. When you open Cheffeurs on your mobile device, we receive your location. We may also collect the precise location of your device when the app is running in the foreground or background. If you label certain locations, such as “home” and “work,” we receive that information, too.</p>
                      <p>Your location information is necessary for things like matching Diners with nearby Cooks, and suggesting Cooks based on previous meals. Also, if the need ever arises, our Trust & Safety team may use and share location information to help protect the safety of Cheffeurs Users or a member of the public. If you give us permission through your device settings or Cheffeurs app, we may collect your location while the app is off to identify promotions or service updates in your area.</p>
                      <p><strong>Device Information.</strong> Cheffeurs receives information from Users’ devices, including IP address, web browser type, mobile operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, push notification tokens, and, if you register with your Facebook account, your Facebook identifier. We collect mobile sensor data from Cooks’ devices (such as speed, direction, height, acceleration or deceleration) to improve location accuracy and analyze usage patterns.</p>
                      <p><strong>Usage Information.</strong> To help us understand how you use the Cheffeurs Platform and to help us improve it, we automatically receive information about your interactions with the Cheffeurs Platform, like the pages or other content you view, your actions within the Cheffeurs app, and the dates and times of your visits.</p>
                      <p><strong>Call and Text Information.</strong> We work with a third party partner to facilitate phone calls and text messages between Diners and Cooks who have been connected for a meal. We receive information about these communications including the date and time of the call or SMS message, the parties’ phone numbers, and the content of any SMS messages. For security purposes, we may also monitor and/or record the contents of phone calls made on the Cheffeurs Platform, such as those between Diners and Cooks. You will be given notice that your call may be recorded, and by proceeding you agree to allow Cheffeurs to monitor and/or record your call.</p>
                      <p><strong>User Feedback.</strong> At Cheffeurs, we want to make sure Users are always enjoying great meals. Diners and Cooks may rate and review each other at the end of every meal. We receive information about ratings and reviews and, as we explain below, give Diners information about Cooks’ ratings and reviews and vice versa.</p>
                      <p><strong>Address Book Contacts.</strong> If you permit Cheffeurs to access the address book on your device through the permission system used by your mobile platform, we may access and store names and contact information from your address book to facilitate invitations and social interactions that you initiate through our Platform and for other purposes described in this privacy policy or at the time of consent or collection.</p>
                      <p><strong>Information from Cookies and Similar Technologies.</strong> We collect information through the use of “cookies”, tracking pixels, and similar technologies to understand how you navigate through the Cheffeurs Platform and interact with Cheffeurs advertisements, to learn what content is popular, and to save your preferences. Cookies are small text files that web servers place on your device; they are designed to store basic information and to help websites and apps recognize your browser. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be accessed every time you use the Cheffeurs Platform. You should consult your web browser(s) to modify your cookie settings. Please note that if you delete or choose not to accept cookies from us, you may be missing out on certain features of the Cheffeurs Platform.</p>
                      <h5>C. Information We Collect from Third Parties</h5>
                      <p><strong>Third Party Services.</strong> If you choose to register for Cheffeurs or otherwise link your Cheffeurs account with a third party’s service (such as Facebook), we may receive the same type of information we collect from you (described above) directly from those services.</p>
                      <p><strong>Third Party Partners.</strong> We may receive additional information about you, such as demographic data, payment information, or fraud detection information, from third party partners and combine it with other information that we have about you.</p>
                      <p><strong>Enterprise Programs.</strong> If your company, university, or organization participates in one of our enterprise programs such as Cheffeurs for Work, we may receive information about you, such as your email address, from your participating organization. We also may give your participating organization the opportunity to request a meal on your behalf, in which case they may provide us with your name, phone number, and the cooking location for your meal.</p>
                      <p><strong>Background Information on Cooks.</strong> Cheffeurs works with third party partners to perform criminal background checks on Cooks, and we receive information from them such as publicly available information about a Cook’s criminal history.</p>

                      <h4 className="caps">How We Use the Information We Collect</h4>
                      <p>We use the information we collect from all Users to:</p>
	                    <ul>
                        <li>Connect Diners with Cooks;</li>
                        <li>Provide, improve, expand, and promote the Cheffeurs Platform;</li>
                        <li>Analyze how the Cheffeurs community uses the Cheffeurs Platform;</li>
                        <li>Communicate with you, either directly or through one of our partners, including for marketing and promotional purposes;</li>
                        <li>Personalize the Cheffeurs experience for you and your friends and contacts;</li>
                        <li>Send you text messages and push notifications;</li>
                        <li>Facilitate transactions and payments;</li>
                        <li>Provide you with customer support;</li>
                        <li>Find and prevent fraud; and</li>
                        <li>Respond to trust and safety issues that may arise, including cooking incidents, disputes between Diners and Cooks, and requests from government authorities.</li>
                      </ul>
                      <p>Additionally, we use the information we collect from Cooks for the following purposes related to driving on the Cheffeurs Platform:</p>
                      <ul>
                        <li>Sending emails and text messages to Cooks who have started the Cook application process regarding the status of their application;</li>
                        <li>Determining a Cook’s eligibility to cook for Cheffeurs</li>
                        <li>Notifying Cooks about meal demand, pricing and service updates; and</li>
                        <li>Calculating and providing Cheffeurs’s insurance policy and analyzing usage patterns for safety and insurance purposes.</li>
                      </ul>

                      <h4 className="caps">How We Share the Information We Collect</h4>
                      <h5>A. Sharing Between Users</h5>
                      <p><strong>Sharing between Diners and Cooks.</strong> Diners and Cooks that have been matched for a meal are able to see basic information about each other, such as names, photo, ratings, and any information they have added to their Profiles. Diners and Cooks who connect their Cheffeurs accounts to Facebook will also be able to see their mutual Facebook friends during the meal. Cooks see the cooking location that the Diner has provided. Diners see a Cook’s vehicle information and real-time location as the Cook approaches the cooking location. Diners’ ratings of Cooks are shared with Cooks on a weekly basis. We de-identify the ratings and feedback, but we can’t rule out that a Cook may be able to identify the Diner that provided the rating or feedback.</p>
                      <p>Although we help Diners and Cooks communicate with one another to arrange a meal, we do not share your actual phone number or other contact information with other Users. If you report a lost or found item to us, we will seek to connect you with the relevant Diner or Cook, including sharing actual contact information with your permission.</p>
                      <h5>B. Sharing Between Cheffeurs and Third Parties</h5>
                      <p><strong>API and Integration Partners.</strong> If you connect to the Cheffeurs Platform through an integration with a third party service, we may share information about your use of the Cheffeurs Platform with that third party. We may share your information with our third party partners in order to receive additional information about you. We may also share your information with third party partners to create offers that may be of interest to you.</p>
                      <p><strong>Third Party Services.</strong> The Cheffeurs Platform may allow you to connect with other websites, products, or services that we don’t have control over (for example, we may give you the ability to order ingredients from a grocery store from within the Cheffeurs app). If you use these services, we will provide the third party with information about you to allow them to provide the service to you (for example, we would give the grocery store your name, phone number and address to drop off the ingredients). We can’t speak to the privacy practices of these third parties, and we encourage you to read their privacy policies before deciding whether to use their services.</p>
                      <p><strong>Service Providers.</strong> We work with third party service providers to perform services on our behalf, and we may share your information with such service providers to help us provide the Cheffeurs Platform, including all of the things described in Section 3 above.</p>
                      <p><strong>Enterprise Partners.</strong> If you participate in an enterprise program and charge a meal to your organization’s billing method or credits, we will provide your organization’s account holder with information about your use of the Cheffeurs Platform, including meal details such as date, time, charge, and cooking location. If you create a Business Profile, at the end of each meal you will have the option to designate the meal as a business meal. If you do so, and your organization has a corporate account with Cheffeurs, we may share information about your use of Cheffeurs Platform with your organization including meal details such as date, time, charge, and region of the meal. If you change organizations, it is your responsibility to update your Business Profile with the new information. (Please remember to check and set your location settings accordingly.) If you integrate your account with an expense platform (like Concur) we will share the meal details to your expense account.</p>
                      <p><strong>International Partners.</strong> We’ve partnered with several cook services around the globe so Diners can continue to find Cooks when they open the Cheffeurs app abroad, and Cooks can provide services to international travelers in the U.S. When we match a meal with the partner, we share the same information that is shared between matched Diners and Cooks on the Cheffeurs Platform. In some cases we are unable to mask your phone number if you call an international Cook, so please keep that in mind before using this feature.</p>
                      <p><strong>Other Sharing.</strong> We may share your information with third parties in the following cases:</p>
                      <ul>
                        <li>While negotiating or in relation to a change of corporate control such as a restructuring, merger or sale of our assets;</li>
                        <li>If a government authority requests information and we think disclosure is required or appropriate in order to comply with laws, regulations, or a legal process;</li>
                        <li>With law enforcement officials, government authorities, or third parties if we think doing so is necessary to protect the rights, property, or safety of the Cheffeurs community, Cheffeurs, or the public;</li>
                        <li>To comply with a legal requirement or process, including but not limited to, civil and criminal subpoenas, court orders or other compulsory disclosures.</li>
                        <li>If you signed up for a promotion with another User’s referral or promotion code, with your referrer to let them know about your redemption of or qualification for the promotion;</li>
                        <li>With our insurance partners to help determine and provide relevant coverage in the event of an incident;</li>
                        <li>To provide information about the use of the Cheffeurs Platform to potential business partners in aggregated or de-identified form that can’t reasonably be used to identify you; and</li>
                        <li>Whenever you consent to the sharing.</li>
                      </ul>
                      <h4 className="caps">Your Choices</h4>
                      <p><strong>Email Subscriptions.</strong> You can always unsubscribe from our commercial or promotional emails but we will still send you transactional and relational emails about your account use of the Cheffeurs Platform.</p>
                      <p><strong>Push Notifications.</strong> You can opt out of receiving push notifications through your device settings. Please note that opting out of receiving push notifications may impact your use of the Cheffeurs Platform (such as receiving a notification that your meal has arrived).</p>
                      <p><strong>Profile Information.</strong> While your name will always be shared with Cooks, you can delete any additional information that you added to your Profile at any time if you don’t want Cooks to see it. Diners will always be able to see Cooks’ names, rating, and profile photos.</p>
                      <p><strong>Location Information.</strong> While you can prevent your device from sharing location information at any time through your Device’s operating system settings, Diner and Cook location is core to the Cheffeurs Platform and without it we can’t provide our services to you.</p>
                      <p><strong>Facebook Friends.</strong> You can control whether to enable or disable the Facebook mutual friends feature through your profile settings.</p>
                      <p><strong>Editing and Accessing Your Information.</strong> You can review and edit certain account information by logging in to your account settings and profile (Cooks may edit additional information through the Cook portal). If you would like to terminate your Cheffeurs account, please contact us through our Help Center with your request. If you choose to terminate your account, we will deactivate it for you but may retain information from your account for a certain period of time and disclose it in a manner consistent with our practices under this Privacy Policy for accounts that are not closed. We also may retain information from your account to collect any fees owed, resolve disputes, troubleshoot problems, analyze usage of the Cheffeurs Platform, assist with any investigations, prevent fraud, enforce our Terms of Service, or take other actions as required or permitted by law.</p>
                      <h4 className="caps">Other</h4>
                      <p><strong>Data Security.</strong> We are committed to protecting the data of the Cheffeurs community. Even though we take reasonable precautions to protect your data, no security measures can be 100% secure, and we cannot guarantee the security of your data.</p>
                      <p><strong>Children’s Privacy.</strong> Cheffeurs is not directed to children, and we don’t knowingly collect personal information from children under 13. If we find out that a child under 13 has given us personal information, we will take steps to delete that information. If you believe that a child under the age of 13 has given us personal information, please contact us at our Help Center.</p>
                      <p><strong>Changes to Our Privacy Policy.</strong> We may make changes to this Privacy Policy from time to time. If we make any material changes, we will let you know through the Cheffeurs Platform, by email, or other communication. We encourage you to read this Privacy Policy periodically to stay up-to-date about our privacy practices. As long as you use the Cheffeurs Platform, you are agreeing to this Privacy Policy and any updates we make to it.</p>
                      <p><strong>Contact Information.</strong> Feel free to contact us at any time with any questions or comments about this Privacy Policy, your personal information, our use and sharing practices, or your consent choices by contacting our Customer Support.</p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Animated>
    )
  }
}
