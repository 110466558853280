//import moment from 'moment'
import { createModelSchema, primitive } from 'serializr'

import BaseClass from './BaseClass'
import API from './Api'


export default class Cook extends BaseClass {
  constructor(attributes) {
    super()

    if (attributes) {
      this.assignCook(attributes)
    }
  }

  // Used to assign the current user from the login/register success
  assignCook(attributes) {
    this.id = attributes.id
    this.first_name = attributes.first_name
    this.last_name = attributes.last_name
    this.full_name = attributes.full_name
    this.address = attributes.address
    this.email = attributes.email
    this.phone = attributes.phone
    this.avatar_url = attributes.avatar_url
    this.auth_token = attributes.auth_token
    this.authenticated = false
    this.avatar_url = attributes.avatar_url
    this.current_state = attributes.current_state

    console.log(this)
  }

  async authenticate() {
    this.authenticated = true
    console.log("CURRENT COOK AUTHENTICATED: ", CurrentCook)
  }

  isAuthenticated() {
    let result = null

    API.fetchAuth(authData => {
      if (typeof authData.auth_token === "undefined" || typeof authData.email === "undefined") {
        result = false
        return
      }

      if (authData.auth_token.length > 0 && authData.email.length > 0) {
        CurrentCook.email = authData.email
        CurrentCook.id = authData.cook_id
        CurrentCook.auth_token = authData.auth_token
        API.setAuthenticationFromCookie()
        //console.log(CurrentCook)
        result = true
        return
      }

      result = false

    }, error => {
      result = false
    })

    return result
  }

  applicationPending() {
    return this.current_state === "pending"
  }

  applicationAccepted() {
    return this.current_state === "active"
  }

  fullName() {
    return this.first_name + " " + this.last_name
  }

  refresh(params = {}, success, error) {
    this.getAssociatedResource(Cook, "/cooks/"+this.id, params, cook => {
      console.log(cook)
      CurrentCook.assignCook(cook)
      success(cook)

    }, errorResponse => {

    })
  }

  update(params = {}, success, error) {
    this.putResource(Cook, "/cooks/"+this.id, {cook: params}, (cook) => {
      console.log(cook)
      success(cook)


    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  uploadAvatar(params = {}, success, error) {
    const form = new FormData()

    if (this.avatar_url) {
      form.append('cook[avatar]', {
        name: Date.parse(new Date())+'.jpg',
        uri: this.avatar_url,
        type: 'image/jpg'
      })
    }

    this.putResource(Cook, "/cooks/"+this.id, form, (cook) => {
      console.log(cook)
      success(cook)


    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  forgotPasswordRequest(params = {}, success, error) {
    this.postResource(Cook, "/cooks/reset_password", params, (cook) => {
      console.log(cook)
      success(cook)


    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  validatePasswordRequest(params = {}, success, error) {
    const submitParams = "cook[email]="+params.cook.email+"&cook[reset_password_token]="+params.cook.reset_password_token
    console.log(submitParams)
    this.getAssociatedResource(Cook, "/cooks/validate_reset_password?"+submitParams, {}, (newToken) => {
      console.log(newToken)
      success(newToken)

    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  updatePasswordRequest(params = {}, success, error) {
    console.log("UPDATE PW REQUEST ", params)

    API.fetchAuth((authData) => {
      API.endpoint.put('/cooks/validate_reset_password', params).then((response) => {
        if (response.ok) {
          console.log(response)
          response.data.cook.onesignal_user_id = authData.onesignal_user_id

          API.setAuthentication({
            auth_token: response.data.auth_token,
            cook: response.data.cook
          })

          CurrentCook.updateOsUserId((hooray) => {
            console.log(hooray)
          }, (ohno) => {
            console.log(ohno)
          })

          success(response)

        } else {
          console.log("ERROR: ", response)
          error(response)
        }
      })

    }, (error) => {
      console.log("THERE WAS AN ERROR AUTHENTICATING WITH SAVED")
      console.log(error)
    })
  }
}

createModelSchema(Cook, {
  id: primitive(),
  first_name: primitive(),
  last_name: primitive(),
  full_name: primitive(),
  address: primitive(),
  email: primitive(),
  phone: primitive(),
  avatar_url: primitive(),
  reset_password_token: primitive(),
  current_state: primitive()
})


export const CurrentCook = new Cook()
